import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Header from './components/Header';
import PlayersColumn from './components/PlayersColumn';
import Ticker from './components/Ticker';
import { formatTime } from './helpers/helpers';
import { useParams } from 'react-router-dom';

function App() {
  const { id_match } = useParams(); // Recupera id_match dai parametri dell'URL
  const [homeTeam, setHomeTeam] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [gameTime, setGameTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [homeFouls, setHomeFouls] = useState(0);
  const [awayFouls, setAwayFouls] = useState(0);
  const [currentHalf, setCurrentHalf] = useState(1);
  const [recentEvents, setRecentEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [eventCounter, setEventCounter] = useState(1);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedPlayerTeam, setSelectedPlayerTeam] = useState(null);
  const [substitutionMode, setSubstitutionMode] = useState(false);
  const [gameState, setGameState] = useState('pre-game');
  const [homeSelectedPlayers, setHomeSelectedPlayers] = useState([]);
  const [awaySelectedPlayers, setAwaySelectedPlayers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEvents, setModalEvents] = useState([]);
  const [homeTeamId, setHomeTeamId] = useState(null);
  const [homeClubId, setHomeClubId] = useState(null);
  const [awayTeamId, setAwayTeamId] = useState(null);
  const [awayClubId, setAwayClubId] = useState(null);
  const [yellowcardPlayers, setYellowcardPlayers] = useState([]); // New state for yellow card players
  const [redCardedPlayers, setRedCardedPlayers] = useState([]); // State per i giocatori espulsi
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // For storing selected event
  const [eventModalVisible, setEventModalVisible] = useState(false);
  const [fieldModalVisible, setFieldModalVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  const videoRef = useRef(null); // Reference to the video player
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoStartTime, setVideoStartTime] = useState(null); // Video start time in seconds

  // Construct the video URL using the match ID
  const videoURL = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_VIDEO_URL}${id_match}.mp4`;

  // Function to set the current time in the video as the match start
  const setVideoStart = () => {
    if (videoRef.current) {
      setVideoStartTime(videoRef.current.currentTime); // Store the current time in the video as the start of the match
      alert(`Match start set at video time: ${formatTime(Math.floor(videoRef.current.currentTime))}`);
    }
  };

  // Stato per gli eventi dell'API
  const [eventsFromAPI, setEventsFromAPI] = useState([]);

  // Stato per i giocatori della partita dall'API
  const [homePlayers, setHomePlayers] = useState([]);
  const [awayPlayers, setAwayPlayers] = useState([]);

  const removeEvent = (index) => {
    setRecentEvents((prevEvents) => prevEvents.filter((_, i) => i !== index));
  };

  // Funzione per ottenere i dettagli della partita dall'API
  const fetchMatchDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_API_URL}/matches/${id_match}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const matchDetails = data.data;

      setHomeTeam(matchDetails.home_club.shortname);
      setAwayTeam(matchDetails.opponent_club.shortname);

      setHomeTeamId(matchDetails.home_team.id);
      setHomeClubId(matchDetails.home_club.id);
      setAwayTeamId(matchDetails.opponent_team.id);
      setAwayClubId(matchDetails.opponent_club.id);

      setHomePlayers(matchDetails.formation.home);
      setAwayPlayers(matchDetails.formation.away);
    } catch (error) {
      console.error('Errore nel recupero dei dettagli della partita dall\'API:', error);
    }
  };

  useEffect(() => {
    if (id_match) {
      fetchMatchDetails(); // Recupera i dettagli della partita al caricamento del componente
    }
  }, [id_match]);


  // Funzione per ottenere gli eventi dall'API
  const fetchEvents = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_API_URL}/dictionary`);
      const data = await response.json();
      setEventsFromAPI(data.data);
    } catch (error) {
      console.error('Errore nel recupero degli eventi dall\'API:', error);
    }
  };

  useEffect(() => {
    fetchEvents(); // Recupera gli eventi al caricamento del componente
  }, []);

  // Funzione per gestire la selezione o sostituzione dei giocatori
  const handlePlayerSelection = (player, team) => {
    if (redCardedPlayers.includes(player.id)) return; // Blocca la selezione di un giocatore espulso

    if (gameState === 'pre-game') {
      // Modalità pre-game: selezione libera dei giocatori
      if (team === 'Casa') {
        if (homeSelectedPlayers.includes(player)) {
          setHomeSelectedPlayers(prev => prev.filter(p => p.id !== player.id));
        } else if (homeSelectedPlayers.length < 5) {
          setHomeSelectedPlayers(prev => [...prev, player]);
        }
      } else if (team === 'Ospite') {
        if (awaySelectedPlayers.includes(player)) {
          setAwaySelectedPlayers(prev => prev.filter(p => p.id !== player.id));
        } else if (awaySelectedPlayers.length < 5) {
          setAwaySelectedPlayers(prev => [...prev, player]);
        }
      }
    } else if (gameState === 'in-game') {
      // Modalità in-game: gestisci sostituzioni
      const isSelectedPlayer = [...homeSelectedPlayers, ...awaySelectedPlayers].some(p => p.id == player.id);
      if (!substitutionMode) {
        setSelectedPlayer(player);
        setSelectedPlayerTeam(team);
        if(!isSelectedPlayer){
          setSubstitutionMode(true);
        }
      } else {
        // Tentativo di sostituzione
        if (team === selectedPlayerTeam) {
          if (team === 'Casa') {
            setHomeSelectedPlayers(prevPlayers => {
              const updatedPlayers = prevPlayers.filter(p => p.id !== player.id);
              return [...updatedPlayers, selectedPlayer];
            });
          } else if (team === 'Ospite') {
            setAwaySelectedPlayers(prevPlayers => {
              const updatedPlayers = prevPlayers.filter(p => p.id !== player.id);
              return [...updatedPlayers, selectedPlayer];
            });
          }

          const currentTime = formatTime(gameTime);
          const exitEvent = {
            id: eventCounter,
            match_id: id_match,
            player_id: player.id, // Giocatore che esce
            player_shortname: player.shortname,
            player_club_id: player.club_id,
            player_team_id: team === 'Casa' ? homeTeamId : awayTeamId,
            home_team_id: homeTeamId,
            home_club_id: homeClubId,
            away_team_id: awayTeamId,
            away_club_id: awayClubId,
            half: currentHalf,
            what: 'exitcode',
            what_name: 'Uscita dal campo',
            when: currentTime,
            min: Math.floor(gameTime / 60),
            sec: gameTime % 60,
            where_x: 0,
            where_y: 0,
            goal_section_x: 0,
            goal_section_y: 0,
            matchTimestamp: 0,
            videoTimestamp: 0,
          };
          const entryEvent = {
            id: eventCounter + 1,
            match_id: id_match,
            player_id: selectedPlayer.id, // Giocatore che entra
            player_shortname: selectedPlayer.shortname,
            player_club_id: selectedPlayer.club_id,
            player_team_id: team === 'Casa' ? homeTeamId : awayTeamId,
            home_team_id: homeTeamId,
            home_club_id: homeClubId,
            away_team_id: awayTeamId,
            away_club_id: awayClubId,
            half: currentHalf,
            what: 'entercode',
            what_name: 'Entrata in campo',
            when: currentTime,
            min: Math.floor(gameTime / 60),
            sec: gameTime % 60,
            where_x: 0,
            where_y: 0,
            goal_section_x: 0,
            goal_section_y: 0,
            matchTimestamp: 0,
            videoTimestamp: 0,
          };

          setAllEvents(prevEvents => [...prevEvents, exitEvent, entryEvent]);
          setEventCounter(eventCounter + 2);

          // Dopo la sostituzione, il giocatore che entra in campo diventa selezionato per gli eventi
          setSelectedPlayer(null);
          setSubstitutionMode(false);
          setSelectedPlayerTeam(null);
        } else {
          console.log("Non è possibile sostituire un giocatore di una squadra diversa.");
        }
      }
    }
  };

// Funzione per determinare se un evento NON richiede la posizione sul campo
  const eventDoesNotRequirePosition = (eventCode) => {
    // Definisci qui i codici degli eventi che NON richiedono la posizione
    const eventsThatDoNotRequirePosition = [
      '9010', // Cartellino giallo
      '9011', // Cartellino rosso
      // Aggiungi altri codici se necessario
    ];
    return eventsThatDoNotRequirePosition.includes(eventCode);
  };

  // Funzione per gestire il click sui tasti evento
  // Funzione per gestire il click sui tasti evento
  const handleEventSelection = (eventCode, fullname) => {
    if (selectedPlayer) {
      const currentTime = formatTime(gameTime);
      const team = homeSelectedPlayers.includes(selectedPlayer) ? 'Casa' : 'Ospite';

      // Aggiornamento dei punteggi e falli se necessario
      if (eventCode >= '4001' && eventCode <= '4011') {
        // Gestione dei gol
        if (team === 'Casa') {
          setHomeScore((prevScore) => prevScore + 1);
        } else if (team === 'Ospite') {
          setAwayScore((prevScore) => prevScore + 1);
        }
      } else if (eventCode === '9001') {
        // Gestione dei falli
        if (team === 'Casa') {
          setHomeFouls((prevFouls) => prevFouls + 1);
        } else if (team === 'Ospite') {
          setAwayFouls((prevFouls) => prevFouls + 1);
        }
      }

      // Gestione cartellini
      if (eventCode === '9010') {
        // Cartellino giallo
        setYellowcardPlayers((prev) => [...prev, selectedPlayer.id]);

        // Controllo per doppio giallo
        const yellowCardsCount = yellowcardPlayers.filter(id => id === selectedPlayer.id).length;
        if (yellowCardsCount === 2) {
          setRedCardedPlayers((prev) => [...prev, selectedPlayer.id]);
          // Rimuovi il giocatore dalla lista dei selezionati
          if (team === 'Casa') {
            setHomeSelectedPlayers(prevPlayers => prevPlayers.filter(p => p.id !== selectedPlayer.id));
          } else {
            setAwaySelectedPlayers(prevPlayers => prevPlayers.filter(p => p.id !== selectedPlayer.id));
          }
        }
      } else if (eventCode === '9011') {
        // Cartellino rosso diretto
        setRedCardedPlayers((prev) => [...prev, selectedPlayer.id]);

        // Rimuovi il giocatore dalla lista dei selezionati
        if (team === 'Casa') {
          setHomeSelectedPlayers(prevPlayers => prevPlayers.filter(p => p.id !== selectedPlayer.id));
        } else {
          setAwaySelectedPlayers(prevPlayers => prevPlayers.filter(p => p.id !== selectedPlayer.id));
        }
      }

      // Creazione dell'evento
      const newEvent = {
        match_id: id_match,
        player_id: selectedPlayer.id,
        player_shortname: selectedPlayer.shortname,
        player_club_id: selectedPlayer.club_id,
        player_team_id: team === 'Casa' ? homeTeamId : awayTeamId,
        home_team_id: homeTeamId,
        home_club_id: homeClubId,
        away_team_id: awayTeamId,
        away_club_id: awayClubId,
        half: currentHalf,
        what: eventCode,
        what_name: fullname,
        when: currentTime,
        min: Math.floor(gameTime / 60),
        sec: gameTime % 60,
        where_x: 0,
        where_y: 0,
        goal_section_x: 0,
        goal_section_y: 0,
        matchTimestamp: 0,
        videoTimestamp: 0,
      };

      if (eventDoesNotRequirePosition(eventCode)) {
        // L'evento NON richiede la posizione sul campo, quindi lo aggiungiamo subito
        setAllEvents((prevEvents) => [...prevEvents, newEvent]);

        // Aggiorna gli eventi recenti
        setRecentEvents((prevEvents) => {
          const updatedEvents = [...prevEvents, newEvent];
          if (updatedEvents.length > 3) {
            updatedEvents.shift();
          }
          return updatedEvents;
        });

        setEventCounter(eventCounter + 1);
      } else {
        // L'evento richiede la posizione sul campo
        setCurrentEvent(newEvent);
        setFieldModalVisible(true);
      }

      // Chiudi il modal degli eventi
      setEventModalVisible(false);
    } else {
      alert('Devi selezionare un giocatore prima di aggiungere un evento.');
    }
  };


// Funzione per identificare i tasti "aggregatori" e mostrare i relativi eventi figli
  const handleAggregatorClick = (parentCode) => {
    const excludedActionCodes = ['Shotsin', 'shotsout', 'passes', 'Wrongpasses', 'Challenge'];

    const relatedEvents = eventsFromAPI.filter(
        (event) => event.grouptype && event.grouptype.toLowerCase() === parentCode.toLowerCase()
    ).filter(
        (event) => !excludedActionCodes.includes(event.actioncode)
    );

    setModalEvents(relatedEvents);
    setEventModalVisible(true);
  };

  const handleSaveGame = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_API_URL}savegame`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          match_id: id_match,
          homeScore,
          awayScore,
          events: allEvents, // Includi tutti gli eventi da salvare
          currentHalf,
          gameTime
        })
      });

      if (response.ok) {
        alert('Partita salvata con successo!');
      } else {
        alert('Errore nel salvataggio della partita.');
      }
    } catch (error) {
      console.error('Errore durante il salvataggio della partita:', error);
      alert('Errore nel salvataggio della partita.');
    }
  };

  // Chiusura del modal
  const closeModal = () => {
    setEventModalVisible(false);
    setFieldModalVisible(false); // Chiudi anche il modal del campo
  };

  // Funzione per far partire o fermare il timer
  const toggleTimer = () => {
    if (gameState === 'pre-game') {
      if (homeSelectedPlayers.length === 5 && awaySelectedPlayers.length === 5) {
        if (videoStartTime === null) {
          alert('Please set the video start point before starting the match.');
          return;
        }
        setIsRunning(!isRunning);
        setGameState('in-game'); // Cambia lo stato a in-game

        // Start or continue playing the video
        if (videoRef.current && !isRunning) {
          videoRef.current.play();
        }

        // Genera gli eventi di entrata per tutti i giocatori selezionati
        generateEnterEvents();
      } else {
        alert('Devi selezionare 5 giocatori per ciascuna squadra prima di iniziare la partita.');
      }
    } else {
      setIsRunning(!isRunning); // Ferma o avvia il timer se siamo in-game

      // Play or pause the video based on the current game state
      if (videoRef.current) {
        if (isRunning) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
      }
    }
  };

  // Effetto per aggiornare il timer ogni secondo
  useEffect(() => {
    let interval = null;
    if (isRunning) {
      interval = setInterval(() => {
        setGameTime((prevTime) => prevTime + 1); // Aggiunge un secondo ogni intervallo
      }, 1000); // Intervallo di 1 secondo
    } else if (!isRunning && gameTime !== 0) {
      clearInterval(interval); // Cancella l'intervallo se il timer è fermo
    }
    return () => clearInterval(interval); // Pulisce l'intervallo quando il componente si smonta
  }, [isRunning, gameTime]);

  // Function to calculate the adjusted game time based on video time and the set start time
  const getAdjustedGameTime = () => {
    if (videoRef.current && videoStartTime !== null) {
      const currentVideoTime = videoRef.current.currentTime;
      return currentVideoTime - videoStartTime; // Return the time difference as the current game time
    }
    return 0; // If not playing, return 0
  };

  // Funzione per gestire il passaggio tra primo e secondo tempo
  const switchHalf = () => {
    if (!isRunning) {
      // Genera gli eventi di uscita per i giocatori attualmente in campo
      generateExitEvents();

      setCurrentHalf(currentHalf === 1 ? 2 : 1);
      setIsRunning(false);
      setGameTime(0);
      setHomeFouls(0);
      setAwayFouls(0);
      setHomeSelectedPlayers([]);
      setAwaySelectedPlayers([]);
      setGameState('pre-game');
    } else {
      alert("Non puoi passare al tempo successivo mentre il timer è in funzione.");
    }
  };

  const generateEnterEvents = () => {
    const currentTime = formatTime(gameTime);

    // Genera eventi di entrata per i giocatori di casa
    homeSelectedPlayers.forEach((player) => {
      const enterEvent = {
        match_id: id_match,
        player_id: player.id,
        player_shortname: player.shortname,
        player_club_id: player.club_id,
        player_team_id: homeTeamId,
        home_team_id: homeTeamId,
        home_club_id: homeClubId,
        away_team_id: awayTeamId,
        away_club_id: awayClubId,
        half: currentHalf,
        what: 'entercode',
        what_name: 'Entrata in campo',
        when: currentTime,
        min: Math.floor(gameTime / 60),
        sec: gameTime % 60,
        where_x: 0,
        where_y: 0,
        goal_section_x: 0,
        goal_section_y: 0,
        matchTimestamp: 0,
        videoTimestamp: 0,
      };

      setAllEvents((prevEvents) => [...prevEvents, enterEvent]);
      setEventCounter((prevCounter) => prevCounter + 1);
    });

    // Genera eventi di entrata per i giocatori ospiti
    awaySelectedPlayers.forEach((player) => {
      const enterEvent = {
        match_id: id_match,
        player_id: player.id,
        player_shortname: player.shortname,
        player_club_id: player.club_id,
        player_team_id: awayTeamId,
        home_team_id: homeTeamId,
        home_club_id: homeClubId,
        away_team_id: awayTeamId,
        away_club_id: awayClubId,
        half: currentHalf,
        what: 'entercode',
        what_name: 'Entrata in campo',
        when: currentTime,
        min: Math.floor(gameTime / 60),
        sec: gameTime % 60,
        where_x: 0,
        where_y: 0,
        goal_section_x: 0,
        goal_section_y: 0,
        matchTimestamp: 0,
        videoTimestamp: 0,
      };

      setAllEvents((prevEvents) => [...prevEvents, enterEvent]);
      setEventCounter((prevCounter) => prevCounter + 1);
    });
  };

  const generateExitEvents = () => {
    const currentTime = formatTime(gameTime);

    // Genera eventi di uscita per i giocatori di casa
    homeSelectedPlayers.forEach((player) => {
      const exitEvent = {
        match_id: id_match,
        player_id: player.id,
        player_shortname: player.shortname,
        player_club_id: player.club_id,
        player_team_id: homeTeamId,
        home_team_id: homeTeamId,
        home_club_id: homeClubId,
        away_team_id: awayTeamId,
        away_club_id: awayClubId,
        half: currentHalf,
        what: 'exitcode',
        what_name: 'Uscita dal campo',
        when: currentTime,
        min: Math.floor(gameTime / 60),
        sec: gameTime % 60,
        where_x: 0,
        where_y: 0,
        goal_section_x: 0,
        goal_section_y: 0,
        matchTimestamp: 0,
        videoTimestamp: 0,
      };

      setAllEvents((prevEvents) => [...prevEvents, exitEvent]);
      setEventCounter((prevCounter) => prevCounter + 1);
    });

    // Genera eventi di uscita per i giocatori ospiti
    awaySelectedPlayers.forEach((player) => {
      const exitEvent = {
        match_id: id_match,
        player_id: player.id,
        player_shortname: player.shortname,
        player_club_id: player.club_id,
        player_team_id: awayTeamId,
        home_team_id: homeTeamId,
        home_club_id: homeClubId,
        away_team_id: awayTeamId,
        away_club_id: awayClubId,
        half: currentHalf,
        what: 'exitcode',
        what_name: 'Uscita dal campo',
        when: currentTime,
        min: Math.floor(gameTime / 60),
        sec: gameTime % 60,
        where_x: 0,
        where_y: 0,
        goal_section_x: 0,
        goal_section_y: 0,
        matchTimestamp: 0,
        videoTimestamp: 0,
      };

      setAllEvents((prevEvents) => [...prevEvents, exitEvent]);
      setEventCounter((prevCounter) => prevCounter + 1);
    });
  };


  // Funzione per gestire il clic sul campo
  const handleFieldClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const imageWidth = rect.width; // Larghezza dell'immagine in pixel
    const imageHeight = rect.height; // Altezza dell'immagine in pixel

    // Calcolo delle coordinate del clic relative all'immagine
    const x = e.clientX - rect.left; // Coordinate x in pixel
    const y = rect.bottom - e.clientY; // Coordinate y in pixel

    // Trasforma le coordinate in metri
    const xMeters = (x / imageWidth) * 20; // Scala la x rispetto ai 20 metri
    const yMeters = (y / imageHeight) * 40; // Scala la y rispetto ai 40 metri

    console.log('Coordinate in metri:', { xMeters, yMeters });

    // Aggiorna l'evento corrente con le coordinate proporzionate
    const updatedEvent = { ...currentEvent, where_x: xMeters, where_y: yMeters };
    setAllEvents((prevEvents) => [...prevEvents, updatedEvent]);

    // Chiudi il modal
    setFieldModalVisible(false);
  };

  return (
      <div className="container">
        {/* Row 1: Header */}
        <Header
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            homeScore={homeScore}
            awayScore={awayScore}
            gameTime={formatTime(gameTime)}
            homeFouls={homeFouls}
            awayFouls={awayFouls}
            isRunning={isRunning}
            toggleTimer={toggleTimer}
            currentHalf={currentHalf}
            switchHalf={switchHalf}
            startDisabled={homeSelectedPlayers.length < 5 || awaySelectedPlayers.length < 5}
            switchHalfDisabled={isRunning}
            handleSaveGame={handleSaveGame} // Passa la funzione di salvataggio
        />

        {/* Row 2: Players and Video */}
        <div className="main-content">
          {homePlayers.length > 0 ? (
              <PlayersColumn
                  players={homePlayers}
                  selectedPlayers={homeSelectedPlayers}
                  handlePlayerSelection={handlePlayerSelection}
                  team="Casa"
                  selectedPlayer={selectedPlayer}
                  yellowcardPlayers={yellowcardPlayers}
                  redCardedPlayers={redCardedPlayers}
              />
          ) : (
              <p>Loading home players...</p>
          )}

          <div className="video-column">
            <video ref={videoRef} width="100%" height="auto" controls>
              <source src={videoURL} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
            <button onClick={setVideoStart}>Set Video Start</button>
            {/* Button to set video start point */}
          </div>

          {awayPlayers.length > 0 ? (
              <PlayersColumn
                  players={awayPlayers}
                  selectedPlayers={awaySelectedPlayers}
                  handlePlayerSelection={handlePlayerSelection}
                  team="Ospite"
                  selectedPlayer={selectedPlayer}
                  yellowcardPlayers={yellowcardPlayers}
                  redCardedPlayers={redCardedPlayers}
              />
          ) : (
              <p>Loading away players...</p>
          )}
        </div>
        {/* Row 2: Debug */}
        <div>
          {'Player: ' + (selectedPlayer ? selectedPlayer.shortname : 'Nessuno') +
              ' SubMode: ' + substitutionMode +
              ' Squadra: ' + (selectedPlayerTeam ? selectedPlayerTeam : 'Nessuna')}
        </div>

        {/* Row 3: Event Buttons */}
        <div className="events-row">

          {eventsFromAPI.length > 0 ? (
              eventsFromAPI
                  .filter(
                      (event) =>
                          isNaN(event.actioncode) &&
                          !['entercode', 'exitcode', 'Shotsin', 'shotsout', 'Wrongpasses', 'ShotAgainstin', 'ShotsOffTarget'].includes(event.actioncode)
                  )
                  .map((event) => (
                      <button key={event.id} onClick={() => handleAggregatorClick(event.actioncode)}>
                        {event.fullname_it}
                      </button>
                  ))
          ) : (
              <p>Caricamento eventi...</p>
          )}

        </div>

        <Ticker recentEvents={recentEvents} removeEvent={removeEvent}/>

        {eventModalVisible && (
            <div className="event-modal">
              <div className="modal-content">
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <h2>Eventi correlati</h2>
                <div className="modal-events">
                  {modalEvents.length > 0 ? (
                      modalEvents.map((event) => (
                          <button key={event.id}
                                  onClick={() => handleEventSelection(event.actioncode, event.fullname_it)}>
                            {event.fullname_it}
                          </button>
                      ))
                  ) : (
                      <p>Nessun evento correlato trovato.</p>
                  )}
                </div>
              </div>
            </div>
        )}

        {fieldModalVisible && (
            <div className="field-modal">
              <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <h2>Seleziona la posizione dell'evento</h2>
                <div
                    style={{display: 'flex', alignItems: 'center', position: 'relative'}}
                    onClick={handleFieldClick} // Aggiungi questo event handler
                >
                  {/* Campo di calcio a 5 */}
                  <img
                      src={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_IMAGE_URL}field_vertical.svg`}
                      alt="Campo di Calcio a 5"
                      className="futsal-field"
                  />
                  {/* Freccia accanto al campo */}
                  <div className="arrow-up"></div>
                  {/* Punto giallo */}
                  <div className="origin-point"></div>
                </div>
              </div>
            </div>
        )}

      </div>
  );
}

export default App;