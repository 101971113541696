import React from 'react';

function PlayersColumn({ players, selectedPlayers, handlePlayerSelection, team, selectedPlayer, yellowcardPlayers, redCardedPlayers }) {
    const orderedPlayers = [
        ...selectedPlayers,
        ...players.filter(player => !selectedPlayers.includes(player)) // Questo mette i non selezionati alla fine
    ];

    return (
        <div className="players-column">
            {orderedPlayers.map((player, index) => (
                <button
                    key={index}
                    onClick={() => handlePlayerSelection(player, team)}
                    className={
                        redCardedPlayers.includes(player.id)
                            ? 'redcard-player'
                            : yellowcardPlayers.includes(player.id)
                                ? 'yellowcard-player'
                                : selectedPlayer && selectedPlayer.id === player.id
                                    ? 'active-selected-player'
                                    : selectedPlayers.includes(player)
                                        ? 'in-field-player'
                                        : ''
                    }
                    disabled={redCardedPlayers.includes(player.id)} // Disabilita il pulsante per i giocatori espulsi
                >
                    <span className={redCardedPlayers.includes(player.id) ? 'redcard-number' : yellowcardPlayers.includes(player.id) ? 'yellowcard-number' : 'player-number'}>
                      {player.jerseynumber}
                    </span>
                    - {player.shortname}
                </button>
            ))}
        </div>
    );
}

export default PlayersColumn;
