import React from 'react';

function Header({
                    homeTeam,
                    awayTeam,
                    homeScore,
                    awayScore,
                    gameTime,
                    homeFouls,
                    awayFouls,
                    isRunning,
                    toggleTimer,
                    currentHalf,
                    switchHalf,
                    startDisabled,
                    switchHalfDisabled,
                    handleSaveGame
                }) {
    return (
        <div className="header-row">
            <div className="team-info">{homeTeam}</div>
            <div className="score">{homeScore} : {awayScore}</div>
            <div className="team-info">{awayTeam}</div>
            <div className="game-time">Tempo: {gameTime}</div>
            <div className="fouls">
                Falli Casa: <span className={homeFouls >= 5 ? 'red-foul' : ''}>{homeFouls}</span> |
                Falli Ospite: <span className={awayFouls >= 5 ? 'red-foul' : ''}>{awayFouls}</span>
            </div>
            <div className="timer">
                <button
                    onClick={toggleTimer}
                    disabled={startDisabled}
                    className={isRunning ? 'button-stop' : 'button-start'}
                >
                    {isRunning ? 'Stop' : 'Start'}
                </button>
            </div>
            <div>
                <button onClick={switchHalf} disabled={switchHalfDisabled}>
                    {currentHalf === 1 ? 'Passa al 2° Tempo' : 'Passa al 1° Tempo'}
                </button>
            </div>
            <div>
                <button onClick={handleSaveGame}>
                    Salva
                </button>
            </div>
        </div>
    );
}

export default Header;
