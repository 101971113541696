import React from 'react';

function Ticker({ recentEvents, removeEvent }) {
    return (
        <div className="ticker-container">
            <div className="ticker">
                {recentEvents.map((event, index) => (
                    <span key={event.id || index} className="ticker-item">
            (# {index}) {event.player_shortname} ({event.player_team_id}) {event.what_name} - {event.when}
                        <button className="del-btn" onClick={() => removeEvent(index)}>DEL</button>
          </span>
                ))}
            </div>
        </div>
    );
}

export default Ticker;
